import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Events from "../services/Events";
import LoadingIndicator from "./LoadingIndicator";
import ImageUpload from "./ImageUpload";
import JSONEditor from "./JSONEditor";
import Field from "./Field";
import AdminBar from "./AdminBar";
import Bloque from "./AdminBloque";
import MaterialIcon from "./MaterialIcon";
import InputSelect from "./InputSelect";
import BarChart from "./estadisticas/BarChart";
import { Colors } from "./AdminEstadisticas";
import L10n from "../services/Locale";
import DotMenu from "./DotMenu";
import EditorSlider from "./EditorSlider.tsx";

import AdminKPINegocio from "./AdminKPINegocio.tsx";
import AdminKPINegocioSuscripciones from "./AdminKPINegocioSuscripciones.tsx";
import Encuesta, { StarMeter } from "./Encuesta.tsx";
import AdminService from "../services/Admin";
import { APIAdminRequest } from "../services/API";
import Helper from "../services/Helper";
import AccordionTab from "./AccordionTab";
import { DialogContext } from "../context/DialogContext";
import AdminWidgetHorario from "./AdminWidgetHorario";
import UI from "../services/UI";
import FormToggle from "./FormToggle";
import { PermissionsContext } from "../context/PermissionsContext";
import SubscriptionsService from "../services/Subscriptions.tsx";
import { SubscriptionsContext } from "../context/SubscriptionsContext.tsx";

export default function AdminEditarNegocio(props) {
	const [negocio, setNegocio] = useState({
		id: 0,
		name: "",
		email: "",
		address_name: "",
		address: "",
		city: "",
		ciudad_id: 0,
		image_url: "",
		description: "",
		tipo: "",
		venue_type_id: 0,
		info: {
			composite: null
		},
		preorder_menu: "{}",
		iconos: "[]",
		iconos_detalle: "[]",
		events: [],
		slider: []
	});

	const [partners, setPartners] = useState();
	const dialogContext = useContext(DialogContext);
	const [types, setTypes] = useState();
	const [cities, setCities] = useState(null);
	const [loading, setLoading] = useState(true);
	const [surveyData, setSurveyData] = useState(null);

	const [statTypes, setStatsTypes] = useState();
	const [kpis, setKpis] = useState();
	const [ordersPerDay, setOrdersPerDay] = useState();
	const [validationErrors, setValidationErrors] = useState({});

	const [sliderEditorShown, setSliderEditorShown] = useState(false);

	const navigate = useNavigate();

	const permissionsContext = useContext(PermissionsContext);
	const subscriptionsContext = useContext(SubscriptionsContext);

	const negocioID = props.negocioID || 0;

	useEffect(() => {
		Promise.all([negocioID == 0 ? Promise.resolve(false) : Events.GetNegocio(negocioID, "", true), AdminService.GetTypes(), APIAdminRequest("get-cities"), AdminService.GetPartners()]).then(([newNegocio, types, citiesResponse, partners]) => {
			const n = { ...(newNegocio || negocio) };
			n.preorder_menu = JSON.parse(n.preorder_menu) || {};
			n.iconos = JSON.parse(n.iconos) || [];
			n.iconos_detalle = JSON.parse(n.iconos_detalle) || [];
			n.tipo = n.tipo || types[0].type;
			n.venue_type_id = n.venue_type_id || types[0].id;
			n.city = n.city || citiesResponse.data[0].name;
			n.ciudad_id = n.ciudad_id || citiesResponse.data[0].id;
			try {
				n.slider = JSON.parse(n.slider || "[]");
			} catch (e) {
				n.slider = [];
			}
			setNegocio(n);
			setTypes(types);
			setCities(citiesResponse.data);
			setPartners(partners);
			setLoading(false);
		});

		if (negocioID > 0) {
			Promise.all([APIAdminRequest("stats-get-types"), APIAdminRequest("stats-get-negocio-kpi", { negocio_id: negocioID }), APIAdminRequest("stats-get-negocio-orders-per-day", { negocio_id: negocioID }), APIAdminRequest("surveys-get-negocio-data", { negocio_id: negocioID })]).then(([types, KPI, ordersPerDay, surveys]) => {
				setStatsTypes(types.data);
				setKpis(KPI.data);
				setOrdersPerDay(ordersPerDay.data);
				setSurveyData(surveys.data);
			});
		}

		document.body.classList.add("has-header");
		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
			document.body.classList.remove("has-header");
		};
	}, []);

	const save = useCallback(
		(notifySaved) => {

			if (!negocio["average_price"]) {
				let average = 0;
				let count = 0;
				negocio.events.forEach((event) => {
					if (event.subscription_data) {
						average += event.subscription_data.price;
						count += 1;
					}
					event.subscription_types?.forEach((type) => {
						if (type.pack) {
							average += type.price;
							count += 1;
						}
					})
				});
				
				if (count > 0) {
					negocio["average_price"] = average / count;
				}
			}

			let validated = true;
			const n = { ...validationErrors };
			for (let key of ["name", "email", "address", "city", "image_url", "partner_id", "description"]) {
				if (!negocio[key]) {
					validated = false;
					n[key] = true;
				}
			}

			if (negocio.info.composite === null) {
				n["horario"] = true;
				validated = false;
			}

			if (!negocio.iconos || negocio.iconos.length == 0) {
				n["iconos"] = true;
				validated = false;
			}

			negocio.iconos.forEach((icono) => {
				if (!icono.description?.length || !icono.image_url?.length) {
					n["iconos"] = true;
					validated = false;
				}
			});

			setValidationErrors(n);

			if (!validated) {
				UI.ShowNotification("Por favor rellena todos los campos obligatorios.");
				notifySaved(false);
				return;
			}

			dialogContext.openDialog(L10n.__("¿Seguro que quieres guardar los cambios?"), L10n.__("Sí"), L10n.__("No"), async (confirmed) => {
				if (confirmed) {
					const n = { ...negocio };
					const promises = [];

					Object.values(n.preorder_menu || {}).forEach((category) => {
						if (category.items)
							category.items.forEach((item) => {
								if (item.image_url) {
									promises.push(AdminService.SaveUploadedImage(item.image_url));
								} else {
									promises.push(Promise.resolve({}));
								}
							});
					});

					n.iconos &&
						n.iconos.forEach((icono) => {
							if (icono.image_url) {
								promises.push(AdminService.SaveUploadedImage(icono.image_url));
							} else {
								promises.push(Promise.resolve({}));
							}
						});

					n.iconos_detalle &&
						n.iconos_detalle.forEach((icono) => {
							if (icono.image_url) {
								promises.push(AdminService.SaveUploadedImage(icono.image_url));
							} else {
								promises.push(Promise.resolve({}));
							}
						});

					if (n.slider && n.slider.map) for (let slide of n.slider) {
						if (slide.background.match(/uploads\/tmp/gi)) {
							const response = await AdminService.SaveUploadedImage(slide.background);
							slide.background = response.data;
						}
					}

					Promise.all(promises).then((responses) => {
						let index;
						let i = 0;
						for (index = 0; index < responses.length - n.iconos.length - n.iconos_detalle.length; index++, i++) {
							const category = Object.values(n.preorder_menu)[i];
							if (category?.items)
								for (let j = 0; j < category?.items.length; j++) {
									if (category.items[j].image_url) {
										if (responses[index].data) n.preorder_menu[Object.keys(n.preorder_menu)[i]].items[j].image_url = responses[index].data;
									}
								}
						}

						let index0 = index;
						for (; index - index0 < n.iconos.length && index < responses.length; index++) {
							if (responses[index].data) n.iconos[index - index0].image_url = responses[index].data;
						}

						index0 = index;
						for (; index - index0 < n.iconos_detalle.length && index < responses.length; index++) {
							if (responses[index].data) n.iconos_detalle[index - index0].image_url = responses[index].data;
						}

						AdminService.SaveUploadedImage(n.image_url).then((response) => {
							if (response.data) {
								n.image_url = response.data;
							}

							if (!n.ciudad_id) {
								n.ciudad_id = cities[0].id;
								n.city = cities[0].name;
							}

							n.slider_enabled = n.slider_enabled ? 1 : 0;

							n.enabled = n.enabled ? 1 : 0;
							APIAdminRequest("save-negocio", n).then((response) => {
								if (!response.status) {
									notifySaved(false);
									if (response.error) {
										UI.ShowNotification(response.error, UI.NOTIFICATION_TYPE_ERROR);
									} else {
										UI.ShowNotification("Ha ocurrido un error de servidor.", UI.NOTIFICATION_TYPE_ERROR);
									}
									return;
								}

								notifySaved(true);
								if (negocioID == 0) {
									navigate("/admin/negocios/" + response.data.id);
								} else {
									setNegocio(n);
								}
							});
						});
					});
				} else {
					notifySaved(false);
				}
			});
		},
		[negocio]
	);

	if (!negocio || !partners) return <LoadingIndicator />;

	const currentPartner = partners.find((partner) => partner.id == negocio.partner_id);
	let currentPartnerString = "";
	if (currentPartner) {
		currentPartnerString = `${currentPartner.name} ${currentPartner.surname} (${currentPartner.login})`;
	}

	let mediaEncuestas = 0;
	let count = 0;
	if (negocioID > 0 && surveyData && surveyData.forEach) {
		surveyData.forEach((item) => {
			if (item.value > 0) {
				mediaEncuestas += item.value;
				count++;
			}
		});
		if (count > 0) {
			mediaEncuestas /= count;
		}
	}

	if (loading) return <LoadingIndicator />;

	return (
		<div className="editar-negocio edit-page">
			<AdminBar onSave={save} />
			<div className="header">
				<h2>{negocio.name ? negocio.name : L10n.__("Nuevo negocio")}</h2>
				{negocioID != 0 && (
					<FormToggle
						tooltip={L10n.__((negocio.enabled ? "Desactivar" : "Activar") + " negocio")}
						large={true}
						defaultValue={negocio.enabled}
						onChange={(value) => {
							const n = { ...negocio };
							n.enabled = value;
							setNegocio(n);
						}}
					/>
				)}
				{negocioID != 0 && (
					<div style={{ marginLeft: "1em" }} className="delete-negocio-container">
						<MaterialIcon
							onClick={(e) => {
								dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar este negocio y todos sus eventos?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
									if (confirmed) {
										APIAdminRequest("delete-negocio", { id: negocioID }).then(() => {
											navigate("/admin/negocios");
										});
									}
								});
							}}
							name="delete"
							tooltip={L10n.__("Borrar negocio")}
						/>
					</div>
				)}
			</div>
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload
						initialValue={negocio.image_url ? (negocio.image_url[0] == "/" ? negocio.image_url : "eventos/" + negocio.image_url) : ""}
						recommendedWidth={1140}
						recommendedHeight={450}
						maxFileSize={600 * 1024}
						className={validationErrors["image_url"] ? "error" : ""}
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.image_url = value;
							setNegocio(newNegocio);
						}}
					/>
					
					<div className="slider-editor-container">
						<div className={"slider-editor-trigger" + (sliderEditorShown ? " active" : "")} onClick={() => setSliderEditorShown(!sliderEditorShown)}><FormToggle defaultValue={negocio?.slider_enabled} onChange={(value) => setNegocio({ ...negocio, slider_enabled: value })} />{L10n.__("Slider de negocio")} <MaterialIcon name={sliderEditorShown ? "expand_less" : "expand_more"} /></div>
						{sliderEditorShown && !!negocio?.slider && <EditorSlider
							className="slider-negocio"
							initialValue={negocio.slider}
							onChange={(sliderData) => {
								setNegocio({ ...negocio, slider: sliderData });
							}}
							imageOnly={true}
						/>}
					</div>
						
					<p />
					{negocio.info != null && (
						<AdminWidgetHorario
							validationError={validationErrors["horario"]}
							composite={negocio.info.composite}
							onSave={(value) => {
								const n = { ...negocio };
								n.info.composite = value;
								setNegocio(n);
								const v = { ...validationErrors };
								v["horario"] = false;
								setValidationErrors(v);
							}}
						/>
					)}
				</div>
				<div className="columna">
					{permissionsContext.user.is_superuser == 1 && partners && partners.length > 0 && (
						<InputSelect
							className={"usuario-administrador" + (validationErrors["partner_id"] ? " error" : "")}
							placeholder={L10n.__("Administrador/a")}
							defaultValue={currentPartnerString}
							options={partners.map((row) => `${row.name} ${row.surname} (${row.login})`)}
							onChange={(value) => {
								const m = value.match(/\((.+?)\)/i);
								if (!m) return;
								const partner = partners.find((partner) => partner.login == m[1]);
								const newNegocio = { ...negocio };
								const v = { ...validationErrors };
								newNegocio.partner_id = partner.id;
								if (newNegocio.email == "") {
									newNegocio.email = m[1];
									$(".custom-field.email input").val(m[1]);
									v["email"] = false;
								}
								setNegocio(newNegocio);
								v["partner_id"] = false;
								setValidationErrors(v);
							}}
						/>
					)}
					<div className="columnas">
						<Field
							placeholder={L10n.__("Nombre")}
							className={"expand" + (validationErrors["name"] ? " error" : "")}
							type="string"
							defaultValue={negocio.name}
							onChange={(value) => {
								const newNegocio = { ...negocio };
								newNegocio.name = value;
								setNegocio(newNegocio);
								const v = { ...validationErrors };
								v["name"] = false;
								setValidationErrors(v);
							}}
						/>
						<select
							className="desplegable-tipos"
							placeholder={L10n.__("Tipo")}
							defaultValue={negocio.venue_type_id}
							onChange={(e) => {
								const newNegocio = { ...negocio };
								newNegocio.venue_type_id = parseInt(e.target.value);
								newNegocio.tipo = types.find((t) => t.id == e.target.value).type;
								setNegocio(newNegocio);
							}}>
							{types &&
								types.map((type, idx) => {
									return (
										<option key={idx} value={type.id}>
											{Helper.UCFirst(type.type)}
										</option>
									);
								})}
						</select>
					</div>
					<div className="columnas">
						<Field
							placeholder={L10n.__("E-mail")}
							type="string"
							className={"email" + (validationErrors["email"] ? " error" : "")}
							defaultValue={negocio.email}
							onChange={(value) => {
								const newNegocio = { ...negocio };
								newNegocio.email = value;
								setNegocio(newNegocio);
								const v = { ...validationErrors };
								v["email"] = false;
								setValidationErrors(v);
							}}
						/>
						<Field
							disabled={subscriptionsContext?.subscriptionsEnabled}
							tooltip={subscriptionsContext?.subscriptionsEnabled && L10n.__("Precio medio")}
							placeholder={L10n.__("Precio medio")}
							type="currency"
							className={"precio-medio" + (validationErrors["average_price"] ? " error" : "")}
							defaultValue={negocio.average_price == -1 || isNaN(negocio.average_price) ? 0 : negocio.average_price}
							onChange={(value) => {
								const newNegocio = { ...negocio };
								newNegocio.average_price = value;
								setNegocio(newNegocio);
								const v = { ...validationErrors };
								v["average_price"] = false;
								setValidationErrors(v);
							}}
						/>
					</div>
					<div className="columnas">
						<Field
							type="address"
							defaultValue={negocio.address}
							placeholder={L10n.__("Dirección")}
							className={"expand" + (validationErrors["address"] ? " error" : "")}
							onChange={(value) => {
								const newNegocio = { ...negocio };
								newNegocio.address = value;
								setNegocio(newNegocio);
								const v = { ...validationErrors };
								v["address"] = false;
								setValidationErrors(v);
							}}
						/>
						<select
							className="desplegable-ciudades"
							placeholder={L10n.__("Ciudad")}
							defaultValue={negocio.ciudad_id}
							onChange={(e) => {
								const newNegocio = { ...negocio };
								newNegocio.city = e.target.options[e.target.selectedIndex].text;
								newNegocio.ciudad_id = e.target.value;
								setNegocio(newNegocio);
							}}>
							{cities &&
								cities.map((city, idx) => {
									return (
										<option key={idx} value={city.id}>
											{Helper.UCFirst(city.name)}
										</option>
									);
								})}
						</select>
					</div>
					{false && (
						<Field
							type="string"
							defaultValue={negocio.address_name}
							placeholder={L10n.__("Ubicación")}
							className={validationErrors["address_name"] ? "error" : ""}
							onChange={(value) => {
								const newNegocio = { ...negocio };
								newNegocio.address_name = value;
								setNegocio(newNegocio);
								const v = { ...validationErrors };
								v["address_name"] = false;
								setValidationErrors(v);
							}}
						/>
					)}
					<Field
						className={"editor-descripcion" + (validationErrors["description"] ? " error" : "")}
						type="text"
						maxLength={1000}
						placeholder={L10n.__("Información sobre el negocio.")}
						defaultValue={negocio.description}
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.description = value;
							setNegocio(newNegocio);
							const v = { ...validationErrors };
							v["description"] = false;
							setValidationErrors(v);
						}}
					/>
					<AccordionTab heading={L10n.__("Iconos")} className={"iconos" + (validationErrors["iconos"] ? " error" : "")}>
						<JSONEditor
							initialValue={negocio.iconos}
							className="iconos"
							onChange={(data) => {
								const newNegocio = { ...negocio };
								newNegocio.iconos = data;
								setNegocio(newNegocio);
							}}
							listTitle={L10n.__("Iconos")}
							properties={[
								{ title: L10n.__("Etiqueta"), key: "description", type: "string", maxLength: 25 },
								{ title: L10n.__("Icono"), key: "image_url", type: "image" }
							]}
						/>
					</AccordionTab>
					<AccordionTab heading={L10n.__("Detalles")} className="detalles">
						<JSONEditor
							className="iconos-detalle"
							initialValue={negocio.iconos_detalle}
							listTitle={L10n.__("Iconos")}
							onChange={(data) => {
								const newNegocio = { ...negocio };
								newNegocio.iconos_detalle = data;
								setNegocio(newNegocio);
							}}
							headingPropertyKey="titulo"
							properties={[
								{ title: L10n.__("Icono"), key: "image_url", type: "image", recommendedWidth: 100, recommendedHeight: 100, maxFileSize: 100 * 1024, fileFormats: ["svg", "png"] },
								{ title: L10n.__("Título"), key: "titulo", type: "string", maxLength: 25 },
								{ title: L10n.__("Descripción"), key: "lista", maxLength: 50, type: "text" }
							]}
						/>
					</AccordionTab>
				</div>
			</Bloque>
			<Bloque>
				{negocio && (
					<div className="eventos">
						<h2>{L10n.__("Eventos")}</h2>
						<div className="eventos-grid">
							{negocio.events != null &&
								negocio.events.map((event, eventIndex) => {
									let price = event.price == -1 ? 0 : event.price;

									if (subscriptionsContext?.subscriptionsEnabled) {
										price = event?.subscription_data?.price ?? 0;
									}

									const image_url = location.hostname == "localhost" ? "http://localhost:8081/" + event.image_url : event.image_url;
									return (
										<div
											className={"evento" + (!event.enabled ? " disabled" : "")}
											key={"evento-" + eventIndex}
											onClick={(e) => {
												if ($(e.target).closest('.dot-menu').length > 0) return;
												navigate("/admin/negocios/" + negocio.id + "/" + event.id);
											}}>
											<DotMenu options={[
												{
													caption: L10n.__("Clonar"),
													action: () => {
														APIAdminRequest("clone-event", { id: event.id }).then(response => {
															if (response.status) {
																navigate("/admin/negocios/" + negocio.id + "/" + response.data);
															} else {
																console.error(response.error);
															}
														});
													}
												}, {
													caption: L10n.__("Eliminar"),
													action: () => {
														dialogContext.openDialog(L10n.__("¿Seguro que quieres borrar este evento?"), L10n.__("Sí"), L10n.__("No"), (confirmed) => {
															if (confirmed) {
																APIAdminRequest("delete-event", { id: event.id }).then(() => {
																	const newNegocio = { ...negocio };
																	newNegocio.events.splice(eventIndex, 1);
																	setNegocio(newNegocio);
																});
															}
														});
													}
												}
											]} />
											{!event.enabled && <div className="disabled-tag">{L10n.__("Oculto")}</div>}
											<div className="image-container" style={{ backgroundImage: "url(" + image_url + ")" }}>
												{!!event.no_calendar && <div className="no-calendar-tag has-tooltip" data-tooltip={L10n.__('Suscripción a producto físico')}>{L10n.__("Producto")}</div>}</div>
											<div className="details">
												<div className="nombre">{event.nombre}</div>
												{(!subscriptionsContext?.subscriptionsEnabled || price != 0) && <div className="price">{Helper.FormatAmount((price == -1 ? 0 : price) / 100)}</div>}
												{subscriptionsContext?.subscriptionsEnabled && event.subscription_types?.length > 0 && (
													<div className="subscription-types">{event.subscription_types.map((st, idx) => <span key={"type-" + event.id + "-" + idx}>{st.name}</span>)}</div>
												)}
											</div>
										</div>
									);
								})}
							<div
								className={"evento nuevo-evento" + (negocio.id == 0 ? " disabled" : "")}
								onClick={() => {
									if (negocio.id == 0) return;
									navigate("/admin/negocios/" + negocio.id + "/0");
								}}>
								<MaterialIcon name="add_circle" />
							</div>
						</div>
					</div>
				)}
			</Bloque>
			{negocioID > 0 && statTypes && kpis && ordersPerDay && (
				<Bloque className="columnas estadisticas" columnCount={2} style={{ paddingTop: 75, position: "relative" }}>
					<h2 style={{ position: "absolute", left: 25, top: 25 }}>{L10n.__("Estadísticas")}</h2>
					<div className="columna" style={{ height: 420 }}>
						<div>
							<h3>{L10n.__("Reservas por día de la semana")}</h3>
							<BarChart singleMode={true} ratios={ordersPerDay} color={Colors[types.findIndex((t) => t.id == negocio.venue_type_id) % Colors.length]} />
						</div>
					</div>
					<div className="columna">
						<h3>{L10n.__("Indicadores de rendimiento")}</h3>
						{subscriptionsContext.subscriptionsEnabled ? <AdminKPINegocioSuscripciones kpis={kpis} /> : <AdminKPINegocio kpis={kpis} />}
					</div>
				</Bloque>
			)}
			{negocioID > 0 && surveyData && surveyData.map && (
				<Bloque className="encuestas columnas" columnCount={1} style={{ paddingTop: 75, position: "relative" }}>
					<h2 style={{ position: "absolute", left: 25, top: 25 }}>{L10n.__("Valoración global")}</h2>
					<div className="columna">
						{surveyData.map((item, index) => {
							return (
								<div className="category" key={"survey-data-category" + index}>
									<h3>{item.text}</h3>
									<StarMeter defaultValue={item.value} />
									<div className="numeric-value">{parseFloat(item.value.toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
								</div>
							);
						})}
						<div className="category mean">
							<h3>{L10n.__("Media")}</h3>
							<StarMeter defaultValue={mediaEncuestas} />
							<div className="numeric-value">{parseFloat(mediaEncuestas.toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
						</div>
					</div>
				</Bloque>
			)}
		</div>
	);
}
