import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from "react-router-dom";
import { APIRequest } from '../services/API';
import Helper from '../services/Helper';
import MaterialIcon from './MaterialIcon';
import LoadingIndicator from './LoadingIndicator.tsx';
import AsyncButton from './AsyncButton.tsx';
import Field from './Field';
import L10n from '../services/Locale';
import {Content} from './Content';

export function StarMeter(props) {
    const [ value, setValue ] = useState(props.defaultValue || 0);

    useEffect(() => {
        if (props.onChange) props.onChange(value);
    }, [ value ]);

    return <div style={props.style || {}} className={"star-meter" + (!props.onChange ? " static" : "")}>
        <MaterialIcon onClick={e => { if (props.onChange) setValue(1); }} name="star_rate" className={value >= 1 ? "active" : ""} style={{ fontVariationSettings: value >= 1 ? '"FILL" 1' : ""}} />
        <MaterialIcon onClick={e => { if (props.onChange) setValue(2); }} name="star_rate" className={value >= 2 ? "active" : ""} style={{ fontVariationSettings: value >= 2 ? '"FILL" 1' : ""}} />
        <MaterialIcon onClick={e => { if (props.onChange) setValue(3); }} name="star_rate" className={value >= 3 ? "active" : ""} style={{ fontVariationSettings: value >= 3 ? '"FILL" 1' : ""}} />
        <MaterialIcon onClick={e => { if (props.onChange) setValue(4); }} name="star_rate" className={value >= 4 ? "active" : ""} style={{ fontVariationSettings: value >= 4 ? '"FILL" 1' : ""}} />
        <MaterialIcon onClick={e => { if (props.onChange) setValue(5); }} name="star_rate" className={value >= 5 ? "active" : ""} style={{ fontVariationSettings: value >= 5 ? '"FILL" 1' : ""}} />
        {props.showNumeric && <div className="numeric-value">{parseFloat(value.toFixed(1)).toLocaleString(L10n.GetLocale())}</div>}
    </div>;
}

export default function Encuesta(props) {
    const params = useParams();
    const [ data, setData ] = useState([]);
    const [ comentarios, setComentarios ] = useState("");
    const saveButtonRef = useRef();
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
		$("header").addClass("simple");

        APIRequest("booking/validate-survey-key", params).then(response => {
            setLoading(false);
            if (!response.status && !response.error) {
                navigate("/");
            } else {
                if (response.error) {
                    setError(response.error);
                } else {
                    setData(response.data);
                }
            }
        });

        return () => {
		    $("header").removeClass("simple");
        };
    }, []);

    if (loading) return <LoadingIndicator />;

    if (error.length) return <div className="encuesta-satisfaccion bg-offwhite">
        <div className="container error">
            <div className="row">
                <div className="col">
                    <h2>{error}</h2>
                    <Link className="btn btn-brown" to="/">{L10n.__("Volver al inicio")}</Link>
                </div>
            </div>
        </div>
    </div>;


    return <div className="encuesta-satisfaccion bg-offwhite">
            {data.map((survey, surveyIndex) => {

            return <div key={"encuesta-data"+surveyIndex}>
                    <div className="event-image" style={{ backgroundImage: "url(" + (survey.image_url[0] == "/" ? survey.image_url : "/static/images/eventos/" + data.image_url) + ")" }} />
                    <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2 className="event-name">{survey.negocio_name}</h2>
                            <h3 className="negocio-name"><span>{Helper.FormatISODate(survey.event_date)}</span> - <span>{survey.event_name}</span></h3>
                            <div><Content slug="encuestas-intro" /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="categories">
                                {survey.survey?.map((category, categoryIndex) => {
                                    return <div className="category" key={"survey-data-category" + categoryIndex}>
                                        <h3>{category.text}</h3>
                                        <StarMeter defaultValue={0} onChange={value => {
                                            const d = {...data};
                                            data[surveyIndex].survey[categoryIndex].value = value;
                                            setData(data);
                                        }} />
                                    </div>;
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row comentarios">
                        <div className="col">
                            <div><Content slug="encuestas-intro-comentarios" /></div>
                            <br />
                            <Field type="text" enableHTML={false} maxLength={500} defaultValue={comentarios} onChange={value => {
                                const d = {...data};
                                data[surveyIndex].comments = value;
                                setData(data);
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        })}
        <div className="save-button-container">
            <AsyncButton
                onClick={(e) => {
                    APIRequest("booking/submit-survey", data).then(response => {
                        saveButtonRef.current?.reset();
                        navigate("/gracias-por-tu-opinion");
                    });
                }}
                className={"save-button"}
                onRef={(ref) => (saveButtonRef.current = ref)}
                caption={L10n.__("Enviar encuesta")}
            />
        </div>
    </div>;
}
