import React, { useState, useEffect, useContext, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import $ from "jquery";

import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import Home from "./Home";
import HomeSuscripcion from "./HomeSuscripcion";
import FichaEvento from "./FichaEvento";
import PlanearEvento from "./PlanearEvento";
import Registro from "./Registro";
import Login from "./Login";
import FormularioEventos from "./FormularioEventos";
import FormularioContacto from "./FormularioContacto";
import FormularioRestaurantes from "./FormularioRestaurantes";
import ContactoGracias from "./ContactoGracias";
import RegistroGracias from "./RegistroGracias";
import Pago from "./Pago";
import PagoSuscripcion from "./PagoSuscripcion";
import ReservaCompletada from "./ReservaCompletada";
import TerminosYCondiciones from "./TerminosYCondiciones";
import PoliticaDePrivacidad from "./PoliticaDePrivacidad";
import GraciasEncuesta from "./GraciasEncuesta.tsx";
import PreguntasFrecuentes from "./PreguntasFrecuentes";
import PopupCookies from "./PopupCookies";
import CambiarContrasena from "./CambiarContrasena";
import RegistroActivar from "./RegistroActivar";
import ReservaFallida from "./ReservaFallida";
import MiCuenta from "./MiCuenta";
import CambioEmail from "./CambioEmail";
import Admin from "./Admin";
import Helper from "../services/Helper";
import { APIRequest } from "../services/API";
import { HeaderContext } from "../services/HeaderContext";
import ContentHelper from "../services/ContentHelper";
import { SessionContext } from "../context/SessionContext";
import Session from "../services/Session";
import LoadingIndicator from "./LoadingIndicator";
import Settings from "../services/Settings";
import SuscripcionCompletada from "./SuscripcionCompletada";
import SuscripcionFallida from "./SuscripcionFallida";

import { DialogContext } from "../context/DialogContext";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import { SettingsContext } from "../context/SettingsContext.tsx";

import ConfirmationDialog from "./ConfirmationDialog";
import Encuesta from "./Encuesta";
import Extras from "./Extras";
import Upselling from "./Upselling";
import SubscriptionsService from "../services/Subscriptions";
import Suscribete from "./Suscribete";
import MisSuscripciones from "./MisSuscripciones";
import FichaGrupoSuscripcion from "./FichaGrupoSuscripcion.tsx";

export default function App() {
	const location = useLocation();
	const resizeTimeout = useRef(null);
	const [bootstrapped, setBootstrapped] = useState(false);
	const [headerButtons, setHeaderButtons] = useState(null);
	const [session, setSession] = useState();
	const [subscriptionsEnabled, setSubscriptionsEnabled] = useState(false);
	const [subscriptionTypes, setSubscriptionTypes] = useState([]);
	const [userSubscriptions, setUserSubscriptions] = useState(null);
	const [allUserSubscriptions, setAllUserSubscriptions] = useState([]);
	const [ selectedSubscriptionType, setSelectedSubscriptionType ] = useState(null);
	const [ settings, setSettings ] = useState({});
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const previousWindowWidth = useRef(window.innerWidth);

	const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
	const [confirmationDialogPrompt, setConfirmationDialogPrompt] = useState("");
	const [confirmationDialogYesText, setConfirmationDialogYesText] = useState("Sí");
	const [confirmationDialogNoText, setConfirmationDialogNoText] = useState("No");
	const [confirmationDialogOnAccepted, setConfirmationDialogOnAccepted] = useState(null);
	const [confirmationDialogOnRejected, setConfirmationDialogOnRejected] = useState(null);

	const navigate = useNavigate();

	function onResize(e) {
		clearTimeout(resizeTimeout.current);
		if (window.innerWidth != previousWindowWidth.current) {
			$("body").css("opacity", 0);
			resizeTimeout.current = setTimeout(() => {
				previousWindowWidth.current = window.innerWidth;
				window.location.reload();
			}, 500);
			return;
		}
	}

	useEffect(() => {
		$("body.scroll-lock").removeClass("scroll-lock");
		window.scrollTo(0, 0);
		Helper.SetDocumentTitle("");
	}, [location]);

	useEffect(() => {
		if (!session || bootstrapped) {
			return;
		}

		Session.CheckIsLoggedIn().then((isLoggedIn) => {
			setIsLoggedIn(isLoggedIn);
			if (!isLoggedIn) {
				sessionStorage.clear();
				navigate("/");
			}
		});
	}, [session]);

	useEffect(() => {
		if (subscriptionsEnabled) {
			$("body").addClass("subscriptions-enabled");
		} else {
			$("body").removeClass("subscriptions-enabled");
		}

		return () => {
			$("body").removeClass("subscriptions-enabled");
		};
	}, [subscriptionsEnabled]);

	useEffect(() => {
		SubscriptionsService.IsEnabled().then(async (enabled) => {
			setSubscriptionsEnabled(enabled);

			if (enabled) {
				const types = await SubscriptionsService.GetTypes();
				setSubscriptionTypes(types);
			}

			const session = await Session.GetSession();
			setSession(session);

			let dynamicEventAttacherInterval;
			let autoTableInterval;

			if (enabled) {
				setUserSubscriptions((await APIRequest("subscriptions/get-user-subscriptions")).data);
				setAllUserSubscriptions((await APIRequest("subscriptions/get-all-user-subscriptions")).data);
			}

			Session.Bootstrap().then(() => {
				$(window).on("resize", onResize);

				let scriptA, scriptB;
				setBootstrapped(true);

				APIRequest("cms/get-analytics-tag").then((response) => {
					if (!response.status || !response.data) return;

					const tag = response.data;

					scriptA = document.createElement("script");
					scriptA.async = true;
					scriptA.src = "https://www.googletagmanager.com/gtag/js?id=" + tag;
					document.body.appendChild(scriptA);

					scriptB = document.createElement("script");
					scriptB.textContent = `
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', '${tag}')
					`;
					document.body.appendChild(scriptB);
				});

				APIRequest("cms/get-favicon").then((response) => {
					document.getElementsByTagName("head")[0].innerHTML += '<link rel="shortcut icon" href="' + response.data + '" type="image/png" /><link rel="apple-touch-icon" href="' + response.data + '" type="image/png" />';
					setTimeout(() => {
						document.getElementById("spotnow").style.opacity = 1;
					}, 500);
				});

				autoTableInterval = setInterval(() => {
					$(".auto-table").each(function () {
						const cellWidths = [];

						$(this)
							.find(".auto-row")
							.each(function () {
								$(this)
									.children()
									.each(function (idx) {
										if ($(this).hasClass("no-auto-width")) return;
										if ($(this).css("flex") != "1 1 0%" && (!cellWidths[idx] || $(this).outerWidth() > cellWidths[idx])) {
											cellWidths[idx] = $(this).outerWidth();
										}
									});
							});

						$(this)
							.find(".auto-row")
							.each(function () {
								$(this)
									.children()
									.each(function (idx) {
										if ($(this).hasClass("no-auto-width")) return;
										$(this).css("min-width", cellWidths[idx]);
									});
								$(this).removeClass("auto-row");
							});

						$(this).find(".no-auto-width").removeClass("no-auto-width");
						$(this).removeClass("auto-table");
					});
				}, 500);

				function showTooltip(e) {
					let tooltipText = e.target.getAttribute("data-tooltip");
					if (!tooltipText) {
						let element = e.target;
						while (!element.getAttribute("data-tooltip") && element.parentElement && element != document.body) {
							element = element.parentElement;
						}
						tooltipText = element.getAttribute("data-tooltip");
					}
					if (tooltipText) {
						if (document.querySelector(".floating-tooltip") && document.querySelector(".floating-tooltip").innerText == tooltipText) return;
						const tooltip = document.querySelector(".floating-tooltip") || document.createElement("div");
						tooltip.classList.add("floating-tooltip");
						tooltip.innerText = tooltipText;
						tooltip.style.top = 20 + e.clientY + "px";
						tooltip.style.opacity = 0;
						if (!document.querySelector(".floating-tooltip")) document.body.appendChild(tooltip);

						setTimeout(() => {
							const boundingRect = tooltip.getBoundingClientRect();
							tooltip.style.left = 10 + e.clientX - boundingRect.width * 0.5 + "px";

							if (boundingRect.right >= window.innerWidth - 10) {
								tooltip.style.left = parseFloat(tooltip.style.left) - (boundingRect.right - window.innerWidth + 10) + "px";
							}
							if (boundingRect.left <= 10) {
								tooltip.style.left = parseFloat(tooltip.style.left) + (10 - boundingRect.left) + "px";
							}
							tooltip.style.opacity = 1;
						});
					} else {
						hideTooltip();
					}
				}

				function hideTooltip() {
					document.querySelectorAll(".floating-tooltip").forEach((element) => element.remove());
				}

				function onClickInhibit(e) {
					e.preventDefault();
					e.stopPropagation();
				}

				dynamicEventAttacherInterval = setInterval(() => {
					document.querySelectorAll(".has-tooltip").forEach((element) => {
						if (!element.tooltipEventListenerAttached) {
							element.addEventListener("mousemove", showTooltip);
							element.addEventListener("mouseout", hideTooltip);
							element.tooltipEventListenerAttached = true;
						}
					});

					document.querySelectorAll(".inhibit-clicks")?.forEach((el) => {
						if (!el.inhibitClicksEventListenerAttached) {
							el.addEventListener("click", onClickInhibit);
							el.inhibitClicksEventListenerAttached = true;
						}
					});
				}, 1000);
			});

			let coverZoomScanInterval = setInterval(() => {
				$(".cover-zoom").each(function () {
					const img = new Image();
					img.onload = () => {
						let imageWidth = this.offsetWidth,
							imageHeight = this.offsetHeight;
						const ratio = img.width / img.height;
						if (ratio * this.offsetHeight < this.offsetWidth) {
							imageHeight = this.offsetWidth / ratio;
						} else {
							imageWidth = ratio * this.offsetHeight;
						}
						$(this).css({
							backgroundSize: imageWidth + "px " + imageHeight + "px",
							transition: "background-size 300ms"
						});
						const target = $(this).closest(".cover-zoom-listener").length ? $(this).closest(".cover-zoom-listener")[0] : this;
						const that = this;
						$(target).on("mouseover", function () {
							$(that).css({ backgroundSize: 1.1 * imageWidth + "px " + 1.1 * imageHeight + "px" });
						});
						$(target).on("mouseout", function () {
							$(that).css({ backgroundSize: imageWidth + "px " + imageHeight + "px" });
						});
					};
					const match = $(this)
						.css("background-image")
						.match(/url\("?(.+?)"?\)/);
					if (match && match[1]) {
						img.src = match[1];
						$(this).removeClass("cover-zoom");
					}
				}, 100);
			});

			Promise.all([
				Settings.Get("LOGO_HEIGHT", 65),
				Settings.Get("LOGO_PADDING", 0)
			]).then(([height, padding]) => {
				height = parseInt(height);
				padding = parseInt(padding);

				if (Helper.IsResponsive()) {
					$("body").append(`<div className="injected-css">
						<style>
							body:not(.admin) {
								padding-top: ${0.75 * (height + 10 + padding * 2)}px;
							}

							.mobile-navbar,
							header,
							header.simple,
							.mobile-navbar .menu-button {
								height: ${0.75 * (height + 10 + padding * 2)}px;
							}

							body .venue-extras .main > .categorias {
								top: ${0.75 * (height + 10 + padding * 2)}px;
							}
						</style>
					</div>`);
				} else {
					$("body").append(`<div className="injected-css">
						<style>
							li.user-account-button-container,
							header.desktop,
							li.user-account-button-close {
								height: ${height + 17 + padding * 2}px;
							}

							.subscriptions-enabled .home .widget-filtro {
								top: ${0.5 * (height + 17 + padding * 2)}px;
								opacity: 1;
							}
						</style>
					</div>`);
				}
			});

			const onClickRemoveTooltip = () => {
				document.querySelector(".floating-tooltip")?.remove();
			};
			document.body.addEventListener("click", onClickRemoveTooltip);

			return function cleanup() {
				$(window).off("resize", onResize);
				if (scriptA) document.body.removeChild(scriptA);
				if (scriptB) document.body.removeChild(scriptB);
				clearInterval(autoTableInterval);
				clearInterval(dynamicEventAttacherInterval);
				clearInterval(coverZoomScanInterval);
				$(".injected-css").remove();

				document.body.removeEventListener("click", onClickRemoveTooltip);

				document.querySelectorAll(".has-tooltip")?.forEach((element) => {
					element.removeEventListener("mousemove", showTooltip);
					element.removeEventListener("mouseout", hideTooltip);
					element.tooltipEventListenerAttached = false;
				});

				document.querySelectorAll(".inhibit-clicks")?.forEach((el) => {
					el.removeEventListener("click", onClickInhibit);
					el.inhibitClicksEventListenerAttached = false;
				});
			};
		});

		Settings.GetAll().then(data => {
			let settings = {};
			data.forEach(item => {
				settings[item.settings_key] = item.settings_value;
			});
		});
	}, []);

	useEffect(() => {
		const id = Helper.GetRandomID();

		if (!Helper.IsResponsive()) {
			$("body").append(`<div id="${id}">
				<style>
					body:not(.admin) {
						padding-top: ${$("header.desktop").outerHeight(true)}px;
					}
				</style>
			</div>`);

			return () => {
				$("body #" + id).remove();
			};
		}
	}); // On render.

	if (!bootstrapped) return <LoadingIndicator />;

	return (
		<SettingsContext.Provider value={ settings }>
		<SubscriptionsContext.Provider
			value={{
				subscriptionsEnabled,
				subscriptionTypes,
				userSubscriptions: isLoggedIn ? (subscriptionsEnabled ? userSubscriptions : null) : [],
				allUserSubscriptions,
				selectedSubscriptionType,
				setSelectedSubscriptionType: (type) => {
					setSelectedSubscriptionType(type);
				},
				isSubscribedToNegocio: (subscriptionType, negocio) => {
					if (!isLoggedIn) return false;
					return userSubscriptions?.filter(us => us.events?.filter(ev => ev.negocio_id == negocio.id && subscriptionType.events.find(e => e.id == ev.id) != null).length > 0).length > 0;
				},
				reload: async () => {
					const subscriptionsEnabled = await SubscriptionsService.IsEnabled();

					if (!subscriptionsEnabled) return;
					
					const subscriptionTypes = await SubscriptionsService.GetTypes();
					const selectedSubscriptionType = selectedSubscriptionType ? subscriptionTypes.find(t => t.id == selectedSubscriptionType.id) : (subscriptionTypes.length ? subscriptionTypes[0] : null);
					const userSubscriptions = (await APIRequest("subscriptions/get-user-subscriptions")).data;
					const allUserSubscriptions = (await APIRequest("subscriptions/get-all-user-subscriptions")).data;

					setSubscriptionsEnabled(subscriptionsEnabled);
					setSelectedSubscriptionType(selectedSubscriptionType);
					setSubscriptionTypes(subscriptionTypes);
					setUserSubscriptions(userSubscriptions);
					setAllUserSubscriptions(allUserSubscriptions);

					return {
						subscriptionsEnabled,
						subscriptionTypes,
						userSubscriptions,
						allUserSubscriptions,
						selectedSubscriptionType
					};
				}
			}}>
			<SessionContext.Provider value={{ session, setSession: session => setSession, logout: () => { setSession(null); Session.Logout(); } }}>
				<DialogContext.Provider
					value={{
						openDialog: (prompt, yes, no, callback) => {
							setConfirmationDialogPrompt(prompt);
							setConfirmationDialogYesText(yes);
							setConfirmationDialogNoText(no);
							setConfirmationDialogOnAccepted(() => () => {
								callback(true);
								setConfirmationDialogVisible(false);
							});
							setConfirmationDialogOnRejected(() => () => {
								callback(false);
								setConfirmationDialogVisible(false);
							});
							setConfirmationDialogVisible(true);
						}
					}}>
					<HeaderContext.Provider
						value={{
							headerButtons,
							setHeaderButtons: (element) => {
								setHeaderButtons(element);
							}
						}}>
						{!Helper.IsResponsive() && (
							<header className="desktop">
								<MainMenu />
							</header>
						)}
						{Helper.IsResponsive() < 768 && (
							<header className="mobile">
								<MobileMenu />
							</header>
						)}
						<section id="main">
							<Routes>
								{subscriptionsEnabled ?
									<Route path="/" element={<HomeSuscripcion />} />
								:
									<Route path="/" element={<Home />} />
								}
								<Route path="/mis-suscripciones" element={<MisSuscripciones />} />
								<Route path="/mis-suscripciones/:subscription_type_slug" element={<MisSuscripciones />} />
								<Route path="/admin" element={<Admin />} />
								<Route path="/admin/:page" element={<Admin />} />
								<Route path="/admin/:page/:args" element={<Admin />} />
								<Route path="/admin/:page/:args/:args2" element={<Admin />} />
								<Route path="/suscripcion/:type_slug/:negocio_slug/" element={<FichaGrupoSuscripcion />} />
								<Route path="/venue/:slug" element={<FichaEvento />} />
								<Route path="/venue/:slug/extras" element={<Extras />} />
								<Route path="/venue/:slug/extras/:page" element={<Upselling />} />
								<Route path="/venue/:slug/:availability_id" element={<FichaEvento />} />
								<Route path="/encuesta/:order_id/:key" element={<Encuesta />} />
								<Route path="/para-empresas" element={<PlanearEvento />} />
								<Route path="/registro" element={<Registro />} />
								<Route path="/login" element={<Login />} />
								<Route path="/contratar-un-evento" element={<FormularioEventos />} />
								<Route path="/contacto" element={<FormularioContacto />} />
								<Route path="/contacto-restaurantes" element={<FormularioRestaurantes />} />
								<Route path="/gracias-por-contactar-con-nosotros" element={<ContactoGracias />} />
								<Route path="/gracias-por-tu-opinion" element={<GraciasEncuesta />} />
								<Route path="/cuenta-creada" element={<RegistroGracias />} />
								<Route path="/activar-usuario" element={<RegistroActivar />} />
								<Route path="/pago" element={<Pago />} />
								<Route path="/completar-reserva" element={<Pago />} />
								<Route path="/completa-tu-suscripcion" element={<PagoSuscripcion />} />
								<Route path="/completa-tu-suscripcion/:slug_or_id" element={<PagoSuscripcion />} />
								<Route path="/reserva-completada" element={<ReservaCompletada />} />
								<Route path="/reserva-fallida" element={<ReservaFallida />} />
								<Route path="/suscripcion-completada" element={<SuscripcionCompletada />} />
								<Route path="/suscripcion-fallida" element={<SuscripcionFallida />} />
								<Route path="/terminos-y-condiciones" element={<TerminosYCondiciones />} />
								<Route path="/politica-de-privacidad" element={<PoliticaDePrivacidad />} />
								<Route path="/preguntas-frecuentes" element={<PreguntasFrecuentes />} />
								<Route path="/cambiar-contrasena" element={<CambiarContrasena />} />
								<Route path="/mi-cuenta" element={<MiCuenta />} />
								<Route path="/cambio-email" element={<CambioEmail />} />
								
								<Route path="/suscribete/:slugNegocio" element={<Suscribete />} />
								<Route path="/suscribete/t/:slugTipo" element={<Suscribete />} />
								<Route path="/suscribete/n/:slugNegocio" element={<Suscribete />} />
								<Route path="/suscribete/n/:slugNegocio/:eventID" element={<Suscribete />} />
								
								{subscriptionsEnabled && <>
									<Route path="/reserva/:slugNegocio" element={<Suscribete />} />
									<Route path="/reserva/t/:slugTipo" element={<Suscribete />} />
									<Route path="/reserva/n/:slugNegocio" element={<Suscribete />} />
									<Route path="/reserva/n/:slugNegocio/:eventID" element={<Suscribete />} />
									
									<Route path="/:subscription_type_slug" element={<HomeSuscripcion />} />	
								</>}
							</Routes>
						</section>
						<PopupCookies />
						{confirmationDialogVisible && <ConfirmationDialog prompt={confirmationDialogPrompt} yesButtonText={confirmationDialogYesText} noButtonText={confirmationDialogNoText} onAccepted={confirmationDialogOnAccepted} onRejected={confirmationDialogOnRejected} />}
					</HeaderContext.Provider>
				</DialogContext.Provider>
			</SessionContext.Provider>
		</SubscriptionsContext.Provider>
		</SettingsContext.Provider>
	);
}
