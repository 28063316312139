import { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FormInput from './FormInput.tsx';
import Session from './../services/Session';
import $ from 'jquery';
import L10n from '../services/Locale.tsx';

import MaterialIcon from './MaterialIcon.tsx';
import Orders from '../services/Orders.tsx';
import LoadingIndicator from './LoadingIndicator.tsx';
import Helper from '../services/Helper.tsx';
import Icon from './Icon.tsx';
import TabMenu from './TabMenu.tsx';
import AsyncButton from './AsyncButton.tsx';
import UI from "../services/UI";
import Modal from "./Modal";
import { SessionContext } from '../context/SessionContext.tsx';
import { SubscriptionsContext } from '../context/SubscriptionsContext.tsx';
import SubscriptionsService from "../services/Subscriptions.tsx";
import DotMenu from './DotMenu.tsx';
import AuthGuard from './AuthGuard.tsx';
import { APIRequest } from '../services/API.ts';
import PaymentMethodCard from './PaymentMethodCard.tsx';
import StripeCheckoutForm from './StripeCheckoutForm.tsx';
import Settings from '../services/Settings.ts';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";

import { DialogContext } from "../context/DialogContext.tsx";

const ORDERS_PER_PAGE = 16;

export default function MiCuenta(props) {
    const [promoCodes, setPromoCodes] = useState([]);
    const [currentOrder, setCurrentOrder] = useState(Session.GetBookingData());
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [name, setName] = useState();
    const [password, setPassword] = useState();
    const [surname, setSurname] = useState();
    const [phone, setPhone] = useState();
    const [login, setLogin] = useState();
    const [ upperMenuPage, setUpperMenuPage ] = useState(0);
    const [ subscriptionLoading, setSubscriptionLoading ] = useState({});
    const [ paymentMethods, setPaymentMethods ] = useState([]);
    const [ loadingPaymentMethods, setLoadingPaymentMethods ] = useState(true);
    const [ stripePromise, setStripePromise ] = useState(null);
    const [ setupIntentSecret, setSetupIntentSecret ] = useState(null);

    const navigate = useNavigate();

    const extrasPopupRef = useRef();
    const changePasswordRef = useRef();
    const saveProfileRef = useRef();
    const closeDotMenuRef = useRef();

    const sessionContext = useContext(SessionContext);
    const subscriptionsContext = useContext(SubscriptionsContext);
    const dialogContext = useContext(DialogContext);
    const allUserSubscriptions = subscriptionsContext?.allUserSubscriptions;

    const loadPaymentMethods = (retries=3) => {
        setLoadingPaymentMethods(true);
        APIRequest("stripe/get-payment-methods").then(response => {
            if (response.data?.length > 0) {
                setPaymentMethods(response.data);
                setLoadingPaymentMethods(false);
            } else {
                Promise.all([
                    Settings.Get("STRIPE_PUBLIC"),
                    APIRequest("stripe/create-setup-intent"),
                ]).then(([key, setupIntentSecretResponse]) => {
                    setLoadingPaymentMethods(false);
                    if (setupIntentSecretResponse.status) {
                        setPaymentMethods([]);
                        setStripePromise(loadStripe(key, { locale: L10n.GetLocale() }));
                        setSetupIntentSecret(setupIntentSecretResponse.data);
                    } else {
                        if (retries > 0) {
                            loadPaymentMethods(retries - 1);
                        } else {
                            setPaymentMethods([]);
                            setStripePromise(null);
                            setSetupIntentSecret(null);
                            console.error(setupIntentSecretResponse.error);
                        }
                    }
                });
            }
        });
    };

    useEffect(() => {
        setTotalPages(Math.ceil(filteredOrders?.length / ORDERS_PER_PAGE));
        setCurrentPage(0);
    }, [filteredOrders]);

    useEffect(() => {
        setSubscriptionLoading({});
    }, [ allUserSubscriptions ]);

    useEffect(() => {
        if (subscriptionsContext?.subscriptionsEnabled) {
            subscriptionsContext?.reload().then(({subscriptionsEnabled}) => {
                Orders.GetOrdersList().then(orders => {
                    setOrders(orders);
                    setFilteredOrders(orders.filter(order => parseInt(order.payment_status) === 1 && order.bookedDateObject > new Date()));
                });

                Session.GetPromoCodes().then(promoCodes => {
                    setPromoCodes(promoCodes);
                });

                if (subscriptionsEnabled) {
                    loadPaymentMethods();
                }
            });
        } else {
            Orders.GetOrdersList().then(orders => {
                setOrders(orders);
                setFilteredOrders(orders.filter(order => parseInt(order.payment_status) === 1 && order.bookedDateObject > new Date()));
            });

            Session.GetPromoCodes().then(promoCodes => {
                setPromoCodes(promoCodes);
            });
        }
        
        document.querySelector("header")?.classList.add("with-border");

        return () => {
            document.querySelector("header")?.classList.remove("with-border");
        };
    }, []);

    function renderMetodosDePago() {
        if (loadingPaymentMethods) return <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><LoadingIndicator contained /></div>;
        return <div className="payment-methods-grid">
            {paymentMethods?.length > 0 && <>
                <PaymentMethodCard paymentMethod={paymentMethods[0]} />
                <button className="btn btn-brown" onClick={e => {
                    e.preventDefault();
                    dialogContext.openDialog(<div>{L10n.__("¿Seguro que quieres eliminar tu tarjeta y configurar una nueva?")}</div>, L10n.__("Sí"), L10n.__("No"), accepted => {
                        if (accepted) {
                            APIRequest("stripe/delete-payment-method", { payment_method_id: paymentMethods[0].id }).then(() => {
                                loadPaymentMethods();
                            });
                        }
                    })
                }}>{L10n.__("Cambiar tarjeta")}</button>
            </>}
            {stripePromise && paymentMethods?.length == 0 && !loadingPaymentMethods && <div>
                <h3>{L10n.__("Configura los datos de tu tarjeta a continuación")}</h3>
                <div className="stripe-widget-container">
                <Elements stripe={stripePromise} options={{ clientSecret: setupIntentSecret }} >
                    <StripeCheckoutForm onConfirmed={() => {
                        loadPaymentMethods();
                    }} buttonCaption={L10n.__("Guardar")} />
                </Elements>
            </div>
            </div>}
        </div>;
    }

    function renderTabCambiarContrasena() {
        return (
            <div>
                <form autoComplete="off"
                className="change-password-form"
                onSubmit={onSubmitContrasena}>
                        <FormInput required
                        type="password"
                        id="password"
                        label={L10n.__("Contraseña")}
                        onValidate={value => {
                            if(value.length < 8) return L10n.__("La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.");
                        }}
                        onChange={value => {
                            setPassword(value);
                        }}
                    />
                    <FormInput required
                        type="password"
                        id="password2"
                        label={L10n.__("Repetir contraseña")}
                        onValidate={value => {
                            if(value.length && password != value) return L10n.__("Las contraseñas no coinciden.");
                        }}
                    />
                </form>
                
                <AsyncButton className="btn btn-brown" ref={ref => changePasswordRef.current = ref} onClick={onSubmitContrasena} caption={L10n.__("Cambiar contraseña")} />
            </div>
        );
    }

    function renderTabUsuario() {
        if (!sessionContext?.session) return null;

        return (
            <div>
                <form autoComplete="off"
                onSubmit={onSubmitUsuario}>
                    <FormInput required type="text" id="nombre" label={L10n.__("Nombre")} initialValue={sessionContext.session.name || ""}
                    onChange={value => {
                        setName(value);
                    }} />
                    <FormInput required type="text" id="apellidos" label={L10n.__("Apellidos")} initialValue={sessionContext.session.surname || ""}
                    onChange={value => {
                        setSurname(value);
                    }} />
                    <FormInput type="phone" id="telefono" label={L10n.__("Teléfono (opcional)")} initialValue={sessionContext.session.phone || ""}
                    onChange={value => {
                        setPhone(value || "");
                    }} />
                    <FormInput type="email" id="email" label={L10n.__("Email")} initialValue={sessionContext.session.login || ""}
                        onValidate={value => {
                            // eslint-disable-next-line no-control-regex
                            if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return 'Por favor introduce una dirección de email válida.';
                        }}
                        onChange={value => {
                            setLogin(value);
                        }}
                    />
                </form>
                <AsyncButton className="btn btn-brown" ref={ref => saveProfileRef.current = ref} onClick={onSubmitUsuario} caption={L10n.__("Guardar")} />
            </div>
        );
    }

    function renderOrderGrid(showOrderAgainButton=true, showCallToAction=false, buttonCaption=L10n.__("REPETIR RESERVA"), page=0, customFilteredOrders=null) {
        let orderInProgress = false;
        const ordersToRender = customFilteredOrders ?? filteredOrders;

        if (currentOrder && currentOrder.selected_event) {
            orders.forEach((order, idx) => {
                if (order.payment_status === 0 && order.event.id === currentOrder.selected_event_id && order.booked_date === currentOrder.selected_full_date) {
                    orderInProgress = true;
                }
            });
        }

		return (
			<>
				<div className="order-grid" style={(Helper.IsResponsive() && ordersToRender.length) ? {width: ordersToRender.length * window.innerWidth * 0.8} : null}>
					{ ordersToRender.slice(currentPage * ORDERS_PER_PAGE, currentPage * ORDERS_PER_PAGE + ORDERS_PER_PAGE).map((order, idx) => {
						return renderOrder(order, idx, showOrderAgainButton, buttonCaption);
					}) }
					{ordersToRender.length > 0 && <p style={{marginBottom: 25 }} />}
					{ordersToRender.length === 0 && <p style={{marginBottom: 25, gridColumnEnd: 4, gridColumnStart: 1 }}>No hay ninguna reserva.{ showCallToAction && <span>&nbsp;<Link to={orderInProgress ? "/pago" : "/"}>Reservar ahora</Link>.</span> }</p>}
				</div>
				{totalPages > 1 && <div className="pagination">
					{Array(totalPages).fill(0).map((v,i) => i).map(idx => {
						return <a key={"page-number-"+idx} href="#" onClick={e => {+
							e.preventDefault();
                            setCurrentPage(idx);
						}} className={"page-button" + (currentPage == idx ? " current" : "")}>{idx+1}</a>
					})}
				</div>}
			</>
		);
    }

    function renderOrder(order, idx, showOrderAgainButton=true, buttonCaption=L10n.__("REPETIR RESERVA")) {
        return (
            <div key={idx} className={["order-item", order.in_progress ? "in-progress" : null].join(" ")} style={{
                marginRight: 5,
                paddingBottom: showOrderAgainButton ? 1 : 10
            }} onClick={e => {
                if (order.in_progress) {
                    e.preventDefault();
                    navigate('/pago');
                }
            }}>
                {order.in_progress && <span>{L10n.__("Reserva pendiente")}</span>}
                <div className="restaurant-image" style={{backgroundImage: 'url(' + (order.event.image_url || order.negocio.image_url) + ')'}}>
                    <div className="tag">{order.negocio.name}</div>
                    {order.preorder_selection.length > 0 && <a className="extras-button btn btn-rounded" href="#" onClick={e => {
                    e.preventDefault();

                    extrasPopupRef.current.open();
                    setTimeout(() => {
                        extrasPopupRef.current.setState({
                            order,
                            custom_title: L10n.__("Extras ") + order.event.nombre
                        });
                    });
                }}>{L10n.__("Ver extras")}</a>}</div>
                <div className="restaurant-name">{order.event.nombre}</div>
                <div className="info-line"><MaterialIcon name="calendar_month" />{Helper.FormatISODate(order.booked_date.split(' ')[0])}</div>
                <div className="info-line"><MaterialIcon name="schedule" />{order.booked_slot}</div>
                <div className="info-line"><MaterialIcon name="account_circle" />{order.pax} {order.pax === 1 ? L10n.__("persona") : L10n.__("personas")}</div>
                {(order.promo && order.promo.title) ?
                    <div className="info-line"><Icon name="promo" />{order.promo.title}</div> :
                    null
                }
                {order.total > 0 && <div className="info-line"><MaterialIcon name="credit_card" />{Helper.FormatAmount(order.total/100, true, true)}</div>}
                { showOrderAgainButton && !order.in_progress && <a className="btn btn-brown rounded" onClick={() => {
                    onRepeatOrder(order)
                }}>{buttonCaption}</a>}
                { showOrderAgainButton && order.in_progress && <Link className="btn btn-brown rounded" to={subscriptionsContext?.subscriptionsEnabled ? "/completar-reserva" : "/pago"}>{L10n.__("COMPLETAR RESERVA")}</Link>}
            </div>
        );
    }

    function onRepeatOrder(order) {
        Session.SetBookingData("selected_restaurant", order.negocio.slug);
        Session.SetBookingData("selected_restaurant_id", order.negocio.id);
        Session.SetBookingData("pax", order.pax);
        Session.SetBookingData("selected_full_date", null);
        Session.SetBookingData("promo_id", null);
        Session.SetBookingData("selected_slot", null);
        Session.SetBookingData("selected_full_slot", null);
        Session.SetBookingData("current_order_id", null);

        if (subscriptionsContext?.subscriptionsEnabled) {
            navigate('/suscripcion/' + order.subscription_type.slug + '/' + order.negocio.slug + "/#" + order.event.id);
        } else {
            navigate("/venue/" + order.event.slug);
        }
    }

    function onSubmitUsuario(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        Session.SaveProfileData({
            name: name || sessionContext.session.name || "",
            surname: surname || sessionContext.session.surname || "",
            phone: phone || sessionContext.session.phone || "",
            login: login || sessionContext.session.login || ""
        }).then(result => {
            if (result.status) {
                UI.ShowNotification(result.data);
                saveProfileRef.current.reset();
            } else {
                UI.ShowNotification(result.data, UI.NOTIFICATION_TYPE_ERROR);
                saveProfileRef.current.reset(false);
            }
        });
    }

    function onSubmitContrasena(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (!password) {
            UI.ShowNotification(L10n.__("Por favor, introduce una contraseña"), UI.NOTIFICATION_TYPE_ERROR);
            changePasswordRef.current.reset();
            return;
        }

        if($('.form-input.not-validated').length === 0) {
            Session.DirectPasswordChange(password).then(result => {
                changePasswordRef.current.reset();
                UI.ShowNotification(result ? L10n.__("Contraseña cambiada") : L10n.__("No se ha podido cambiar la contraseña"), result ? null : UI.NOTIFICATION_TYPE_ERROR);
            });
        } else {
            changePasswordRef.current.reset();
        }
    }

    if (!orders) return <LoadingIndicator />;

    let orderInProgress = null;

    orders.forEach((order, idx) => {
        order.bookedDateObject = Helper.CreateDateCompatible(order.booked_date);
        order.bookedDateObject.setHours(order.booked_date.split(" ")[1].split(":")[0], order.booked_date.split(" ")[1].split(":")[1], order.booked_date.split(" ")[1].split(":")[2]);
        if (parseInt(order.payment_status) === 0 && order.event.id === currentOrder.selected_event_id && order.booked_date === currentOrder.selected_full_date) {
            orderInProgress = order;
            orderInProgress.in_progress = true;
        }
    });

    return (
        <AuthGuard>
        <div className={"mi-cuenta" + (subscriptionsContext?.subscriptionsEnabled ? " subscriptions-enabled" : "")}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>{L10n.__("Mi cuenta")}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col upper-tab-menu-container">
                        <TabMenu
                        tabs={[
                            {
                                caption: L10n.__("Usuario"),
                                render: renderTabUsuario,
                                onEnter: () => {
                                    $("#nombre").val(sessionContext.session.name || "");
                                    $("#apellidos").val(sessionContext.session.surname || "");
                                    $("#telefono").val(sessionContext.session.phone || "");
                                    $("#email").val(sessionContext.session.login || "");
                                }
                            },
                            {
                                caption: L10n.__("Cambiar contraseña"),
                                render: renderTabCambiarContrasena,
                                onEnter: () => {
                                    $("#password, #password2").val("");
                                }
                            },
                            {
                                condition: subscriptionsContext?.subscriptionsEnabled,
                                caption: L10n.__("Métodos de pago"),
                                render: renderMetodosDePago,
                                onEnter: () => {}
                            },
                            {
                                caption: L10n.__("Cerrar sesión"),
                                onClick: () => {
                                    dialogContext.openDialog(<div>{L10n.__("¿Seguro que quieres cerrar tu sesión?")}</div>, L10n.__("Sí"), L10n.__("No"), accepted => {
                                        if (accepted) {
                                            Session.Logout().then(() => {
                                                location.href = '/';
                                            });
                                        }    
                                    });
                                }
                            }
                        ]}
                        />
                    </div>
                    { promoCodes.length > 0 &&
                    <div className="col-lg col-sm-12">
                        <h3>{L10n.__("Códigos descuento")}</h3>
                        {promoCodes.map((promoCode, idx) => {
                            return (
                                <div className="promo-code-row" key={idx}>
                                    <div className="promo-code-image-container" style={{ backgroundImage: "url(/static/images/promos/" + promoCode.image + ")" }} />
                                    <div>
                                        <div className="promo-code-title">{promoCode.title}</div>
                                        <div className="promo-code-description">{promoCode.description}</div>
                                        <div className="promo-code-code">{L10n.__("Código:")} {promoCode.code}</div>
                                        <div className="promo-code-description">{L10n.__("Caduca")} {Helper.FormatISODate(promoCode.expiration_date, 2)}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div> }
                    { promoCodes.length === 0 && !subscriptionsContext?.subscriptionsEnabled && <div className="col-lg col-sm-12 no-promos">
                        <h3>{L10n.__("Códigos descuento")}</h3>
                        <div className="promo-code-row">
                            <div className="promo-code-image-container" />
                            <div>
                                <div className="promo-code-title">{L10n.__("No hay descuentos activos")}</div>
                                <div className="promo-code-description"><span className="placeholder-bar" style={{width: "40%"}} /></div>
                                <div className="promo-code-code"><span className="placeholder-bar" style={{width: "70%"}} /></div>
                                <div className="promo-code-description"><span className="placeholder-bar" style={{width: "60%"}} /></div>
                            </div>
                        </div>
                    </div> }
                    { orderInProgress &&
                    <div className="col-lg-3 col-sm-12">
                        <div className="current-order-widget">
                            {renderOrder(orderInProgress, 0)}
                        </div>
                    </div> }
                    {/* { !orderInProgress && <div className="col-lg-3 col-sm-12">
                        <div className="order-item pending-order-placeholder" style={{
                            paddingBottom: 1
                        }} onClick={e => {}}>
                            <div className="restaurant-image" style={{backgroundColor: '#dedede77'}}></div>
                            <div className="restaurant-name"></div>
                            <div className="info-line"><MaterialIcon name="calendar_month" /><span className="placeholder-bar" style={{width: "20%"}} /></div>
                            <div className="info-line"><MaterialIcon name="schedule" /><span className="placeholder-bar" style={{width: "40%"}} /></div>
                            <div className="info-line"><MaterialIcon name="account_circle" /><span className="placeholder-bar" style={{width: "18%"}} /></div>
                            <Link className="btn btn-brown rounded" to="/">{L10n.__("RESERVAR AHORA")}</Link>
                        </div>
                    </div> } */}
                </div>

                <hr style={{ borderTopColor: "rgba(0,0,0,.4)" }} />

                <div className="row">
                    <div className="col tab-menu-upper">
                        {subscriptionsContext?.subscriptionsEnabled && <a href="#" className={"suscripciones" + (upperMenuPage == 0 ? " active" : "")} onClick={e => {
                            e.preventDefault();
                            setUpperMenuPage(0);
                        }}>{L10n.__("Suscripciones")}</a>}
                        <a href="#" className={"reservas" + (upperMenuPage == 1 && subscriptionsContext?.subscriptionsEnabled ? " active" : "")} onClick={e => {
                            e.preventDefault();
                            setUpperMenuPage(1);
                        }}>{L10n.__("Reservas")}</a>
                    </div>
                </div>
                {(upperMenuPage == 1 || !subscriptionsContext?.subscriptionsEnabled) && <TabMenu
                className="tab-menu-reservas"
                onChange={(_, tabIndex) => {
                    switch(tabIndex) {
                        default:
                        case 0:
                            setFilteredOrders(orders.filter(order => parseInt(order.payment_status) === 1 && order.bookedDateObject > new Date()));
                            break;
                        case 1:
                            setFilteredOrders(orders.filter(order => parseInt(order.payment_status) === 1 && order.bookedDateObject <= new Date()));
                            break;
                        case 2:
                            setFilteredOrders(orders.filter(order => parseInt(order.payment_status) === 0 && !order.in_progress));
                            break;
                    }
                }}
                tabs={[
                    {
                        caption: L10n.__("Próximas"),
                        render: () => {
                            return renderOrderGrid(false, true, L10n.__("RESERVAR AHORA"), currentPage, orders.filter(order => parseInt(order.payment_status) === 1 && order.bookedDateObject > new Date()));
                        }
                    },
                    {
                        caption: L10n.__("Pasadas"),
                        render: () => {
                            return renderOrderGrid(true, false, L10n.__("RESERVAR AHORA"), currentPage, orders.filter(order => parseInt(order.payment_status) === 1 && order.bookedDateObject <= new Date()));
                        }
                    },
                    {
                        caption: L10n.__("Canceladas"),
                        render: () => {
                            return renderOrderGrid(true, false, L10n.__("RESERVAR AHORA"), currentPage, orders.filter(order => parseInt(order.payment_status) === 0 && !order.in_progress));
                        }
                    }
                ]}
                />}
                {subscriptionsContext?.subscriptionsEnabled && allUserSubscriptions && upperMenuPage == 0 && <TabMenu
                className="tab-menu-suscripciones"
                onScroll={() => {
                	if (closeDotMenuRef.current) closeDotMenuRef.current();
                }}
                tabs={[
                    {
                        caption: L10n.__("Activas"),
                        render: () => allUserSubscriptions?.filter(s => s.active).map(subscription => {
                            const startDate = new Date(subscription.current_period_start * 1000);
                            const endDate = new Date(subscription.current_period_start * 1000);

                            const interval = subscription.event_subscription_data ? subscription.event_subscription_data.interval : subscription.subscription_type.interval;
                            const interval_type = subscription.event_subscription_data ? subscription.event_subscription_data.interval_type : subscription.subscription_type.interval_type;
                            
                            if (interval_type == "month") {
                                endDate.setMonth(endDate.getMonth() + interval);
                            } else if (interval_type == "year") {
                                endDate.setFullYear(endDate.getFullYear() + interval);
                            } else if (interval_type == "day") {
                                endDate.setDate(endDate.getDate() + interval);
                            }

                            const remainingVisits = subscription.remaining_visits * subscription.pax;

                            const showVisitsTag = subscription.events.filter(ev => ev.no_calendar != 1).length > 0 && subscription.visits * subscription.pax > -1;

                            const showNoCalendarTag = subscription.events.filter(ev => ev.no_calendar != 1).length == 0;

                            const negocioNombre = subscription.events?.filter(ev => ev.id == subscription.event_id)[0]?.negocio_nombre;

                            const eventoNombre = subscription.events?.filter(ev => ev.id == subscription.event_id)[0]?.nombre;

                            let intervalLabel = "mes";
                            if (subscription.event_subscription_data) {
                                intervalLabel = Helper.GetIntervalLabel(subscription.event_subscription_data.interval, subscription.event_subscription_data.interval_type);
                            } else {
                                intervalLabel = Helper.GetIntervalLabel(subscription.subscription_type.interval, subscription.subscription_type.interval_type);
                            }
                            
                            return <div className={"subscription-card" + (subscriptionLoading[subscription.id] ? " loading" : "")} key={"subscription-card-" + subscription.id}>
                                {subscriptionLoading[subscription.id] && <LoadingIndicator contained={true} withBackground={true}/>}
                                <div className="image" style={{ backgroundImage: "url(" + subscription.image_url + ")" }}>
                                    {subscription.event_id ? <div className="tag">{negocioNombre?.trim().toLowerCase() != eventoNombre?.trim().toLowerCase() ? negocioNombre : subscription?.subscription_type?.name}</div> : <div className="tag">{L10n.__('PACK')}</div>}
                                    {showVisitsTag && <div className="tag visitas has-tooltip" data-tooltip={(remainingVisits == 1 ? L10n.__("Te queda") : L10n.__("Te quedan")) + " " + remainingVisits + " " + (remainingVisits == 1 ? L10n.__("visita") : L10n.__("visitas"))}>{remainingVisits}/{subscription.visits * subscription.pax} visitas</div>}
                                    {showNoCalendarTag && <div className="tag no-calendar-tag has-tooltip" data-tooltip={L10n.__('Suscripción a producto físico')}>{L10n.__("Producto")}</div>}
                                    {subscription.cancel_at_period_end && <div className="tag no-renewal-tag">{L10n.__("No se renovará")}</div>}
                                </div>
                                <div className="title">{subscription.event_id ? eventoNombre : subscription.subscription_type?.name}</div>
                                {!subscription.cancel_at_period_end && <div className="info-line"><span className="material-symbols-rounded">calendar_month</span>&nbsp;{Helper.FormatDate(startDate, Helper.DATE_VERBOSE_SHORT)} a {Helper.FormatDate(endDate, Helper.DATE_VERBOSE_SHORT)}&nbsp;({(""+startDate.getHours()).padStart(2, "0")}:{(""+startDate.getMinutes()).padStart(2, "0")})</div>}
                                {subscription.cancel_at_period_end && <div className="info-line"><span className="material-symbols-rounded">calendar_month</span>&nbsp;hasta {Helper.FormatDate(endDate, Helper.DATE_VERBOSE_SHORT)}&nbsp;({(""+endDate.getHours()).padStart(2, "0")}:{(""+endDate.getMinutes()).padStart(2, "0")})</div>}
                                <div className="info-line"><span className="material-symbols-rounded">account_circle</span>&nbsp;{subscription.pax} {L10n.__(subscription.pax == 1 ? "persona" : "personas")}</div>
                                <div className="info-line"><span className="material-symbols-rounded">credit_card</span>&nbsp;{Helper.FormatAmount(subscription.total_fee / 100, true, true)}/{intervalLabel}</div>
                                <DotMenu
	                                closeMenuRef={ref => {
		                                closeDotMenuRef.current = ref;
	                                }}
	                                options={[
	                                    subscription.remaining_visits * subscription.pax > 0 && {
	                                        caption: L10n.__("Reservar"),
	                                        action: () => {
	                                            if (subscription.event_id) {
	                                                navigate("/suscripcion/" + subscription.subscription_slug + "/" + subscription.events[0].negocio_slug + "#" + subscription.event_id);
	                                            } else {
	                                                navigate("/mis-suscripciones/" + subscription.subscription_type.slug);
	                                            }
	                                        }
	                                    },
	                                    (subscription.event_subscription_data?.renewable == 1 || (!subscription.event_subscription_data && subscription.subscription_type.renewable == 1)) && !subscription.cancel_at_period_end && {
	                                        caption: L10n.__("Cancelar renovación"),
	                                        action: () => {
	                                            const startDate = new Date(subscription.start_date.split("-")[0], subscription.start_date.split("-")[1]-1, subscription.start_date.split("-")[2]);
	                                            const endDate = new Date(startDate);
	                                            endDate.setMonth(endDate.getMonth() + 1);

	                                            dialogContext.openDialog(<><div>{L10n.__("¿Seguro que quieres cancelar tu suscripción?")}</div><div>{L10n.__("Podrás seguir disfrutando de ella hasta el") + " " + Helper.FormatDate(endDate)}</div></>, L10n.__("Sí"), L10n.__("No"), accepted => {
	                                                if (accepted) {
	                                                    setSubscriptionLoading({...subscriptionLoading, [subscription.id]: true });
	                                                    APIRequest("subscriptions/cancel", { id: subscription.id }).then(() => {
	                                                        subscriptionsContext.reload();
	                                                    });
	                                                }
	                                            });
	                                        }
	                                    },
	                                    (subscription.event_subscription_data?.renewable == 1 || (!subscription.event_subscription_data && subscription.subscription_type.renewable == 1)) && subscription.cancel_at_period_end && {
	                                        caption: L10n.__("Reanudar renovación"),
	                                        action: () => {
	                                            const startDate = new Date(subscription.start_date.split("-")[0], subscription.start_date.split("-")[1]-1, subscription.start_date.split("-")[2]);
	                                            const endDate = new Date(startDate);
	                                            endDate.setMonth(endDate.getMonth() + 1);

	                                            dialogContext.openDialog(<><div>{L10n.__("¿Quieres volver a activar tu suscripción?")}</div><div>{L10n.__("Si la reanudas, se renovará automáticamente el") + " " + Helper.FormatDate(endDate)}</div></>, L10n.__("Sí"), L10n.__("No"), accepted => {
	                                                if (accepted) {
	                                                    setSubscriptionLoading({...subscriptionLoading, [subscription.id]: true });
	                                                    APIRequest("subscriptions/resume", { id: subscription.id }).then(() => {
	                                                        subscriptionsContext.reload();
	                                                    });
	                                                }
	                                            });
	                                        }
	                                    },
	                                    {
	                                        caption: L10n.__("Detalles"),
	                                        action: () => {
	                                            if (subscription.event_id) {
	                                                navigate("/suscribete/n/" + subscription.events[0].negocio_slug + "/" + subscription.event_id);
	                                            } else {
	                                                navigate("/suscribete/t/" + subscription.subscription_type.slug);
	                                            }
	                                        }
	                                    }
									]}
								/>
                            </div>;
                        })
                    },
                    {
                        caption: L10n.__("Inactivas"),
                        render: () => allUserSubscriptions?.filter(s => !s.active).map(subscription => {
                            return <div className="subscription-card" key={"subscription-card-" + subscription.id}>
                                <div className="image" style={{ backgroundImage: "url(" + subscription.image_url + ")" }}>
                                    {subscription.event_id ? <div className="tag">{subscription.events?.filter(ev => ev.id == subscription.event_id)[0].negocio_nombre}</div> : null}
                                </div>
                                <div className="title">{subscription.event_id ? subscription.events?.filter(ev => ev.id == subscription.event_id)[0].nombre : subscription.subscription_type?.name}</div>
                                <div className="info-line"><span className="material-symbols-rounded">calendar_month</span>&nbsp;{Helper.FormatISODate(subscription.start_date, Helper.DATE_VERBOSE_SHORT)}</div>
                                <div className="info-line"><span className="material-symbols-rounded">account_circle</span>&nbsp;{subscription.pax} {L10n.__(subscription.pax == 1 ? "persona" : "personas")}</div>
                                <DotMenu
                                	closeMenuRef={ref => {
		                                closeDotMenuRef.current = ref;
	                                }}
	                                options={[
	                                    ((subscription.subscription_type.pack == 1 && subscription.subscription_type.renewable == 1) || (subscription.subscription_type.pack == 0 && subscription.event_subscription_data && subscription.event_subscription_data.renewable == 1)) && {
	                                        caption: L10n.__("Reactivar suscripción"),
	                                        action: () => {
	                                            SubscriptionsService.SetCartData({
	                                                typeSlug: subscription.subscription_slug,
	                                                typeID: subscription.subscription_type_id,
	                                                typeMode: subscription.pack == 1 ? SubscriptionsService.SUBSCRIPTION_MODE_TIPO : SubscriptionsService.SUBSCRIPTION_MODE_EVENTO,
	                                                param: subscription.pack == 1 ? subscription.subscription_slug : subscription.event_id
	                                            });
	                                            navigate("/completa-tu-suscripcion");
	                                        }
	                                    },
	                                    {
	                                        caption: L10n.__("Detalles"),
	                                        action: () => {
	                                            if (subscription.event_id) {
	                                                navigate("/suscribete/n/" + subscription.events[0].negocio_slug + "/" + subscription.event_id);
	                                            } else {
	                                                navigate("/suscribete/t/" + subscription.subscription_type.slug);
	                                            }
	                                        }
	                                    }
	                                ]}
								/>
                            </div>;
                        })
                    }
                ]}
                />}
            </div>
            <Modal ref={ref => extrasPopupRef.current = ref} className="extras-modal" renderContent={modal => {
                return modal.state.order?.preorder_selection.map((item, idx) => {
                    return <div className="item" key={idx}>
                        <div className="image" style={{ backgroundImage: "url(" + item.image_url + ")" }}>
                            <div className="quantity">{item.quantity}</div>
                        </div>
                        <div className="details">
                            <div className="name">{item.name}</div>
                            <div className="description" dangerouslySetInnerHTML={{__html: item.description}} />
                        </div>
                    </div>;
                })
            }} />
        </div>
        </AuthGuard>
    );
};
